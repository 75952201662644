import type { Confirm, ConfirmItem } from '../types/confirm'

export default () => {
  const state = useState<Confirm>('confirm', () => ({
    open: false,
    item: null
  }))

  const actions = {
    show (item: ConfirmItem) {
      mutations.setOpen(true)
      mutations.setItem(item)
    },

    hide () {
      mutations.setOpen(false)
    }
  }

  const mutations = {
    setOpen (value: boolean) {
      state.value.open = value
    },

    setItem (item: ConfirmItem) {
      state.value.item = item
    }
  }

  return {
    state,
    actions,
    mutations
  }
}
